<template>
    <admin-template>
        <template slot="content">
            <v-toolbar flat dense color="transparent" height="60" class="mb-2">
                <v-toolbar-title>
                    <span class="headline">Zonas de Entrega</span>
                </v-toolbar-title>
            </v-toolbar>

            <v-row>
                <v-col cols="12">
                    <v-card>
                        <v-card-text>
                            <keep-alive>
                                <v-data-table
                                    hide-default-header
                                    hide-default-footer
                                    :items="deliveryZones"
                                    :loading="isLoading"
                                    :headers="headers"
                                    :items-per-page="-1"
                                    @click:row="onDeliveryZoneSelected"
                                >
                                    <template
                                        v-slot:item.shippingCost="{ item }"
                                    >{{ item.shippingCost | toCurrency }}</template>

                                    <template v-slot:item.isActive="{ item }">
                                        <v-chip v-if="item.isActive" label dark color="green">Activa</v-chip>
                                        <v-chip v-else label dark color="red">Inactiva</v-chip>
                                    </template>
                                </v-data-table>
                            </keep-alive>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </template>
    </admin-template>
</template>

<script>
import AdminTemplate from '../AdminTemplate.vue';

export default {
    components: {
        AdminTemplate
    },

    data() {
        return {
            isLoading: false,

            headers: [
                { text: 'Nombre de la Zona', value: 'name', sortable: false },
                { text: 'Costo de Entrega', value: 'shippingCost', sortable: false },
                { text: '', value: 'isActive', sortable: false }
            ],

            deliveryZones: []
        };
    },

    computed: {},

    watch: {},

    mounted() {
        this.fetchDeliveryZones();
    },

    methods: {
        fetchDeliveryZones() {
            this.isLoading = true;

            let filters = {};

            this.$api.getDeliveryZones(filters).then((result) => {
                this.deliveryZones = result;
                this.isLoading = false;
            });
        },

        onDeliveryZoneSelected(zone) {
            this.$router.push({ name: 'deliveryZoneView', params: { id: zone.id } });
        }
    }
};
</script>

<style lang="scss" scoped>
</style>
